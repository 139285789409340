@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;700&family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap);
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  margin: 0;
  font-family: 'Raleway', sans-serif;
  color: #0d1636;
  background: white;
}


